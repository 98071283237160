import React from 'react';
import './Footer.css';

export default () => (
  <div>
    <h2 className="taCenter">
      Join our{' '}
      <a target="_blank" rel="noopener noreferrer" href="https://facebook.com/groups/ketoplaces">
        facebook group
      </a>
    </h2>
    <br />
    <footer className="footer">
      <div className="container taCenter">
        <span>
          © Copyright {new Date().getFullYear()} All rights reserved. Created by{' '}
          <a href="https://wheretoketo.com">Wheretoketo</a>.
        </span>
      </div>
    </footer>
  </div>
);
